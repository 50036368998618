import * as React from "react";
import LandingPage from "./LandingPage/LandingPage";

import "../assets/css/styles.css";
import "../../node_modules/@fortawesome/fontawesome-free/css/all.min.css";

const index = () => {
  return <LandingPage />;
};

export default index;
